.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  padding: 0;
  font-family: "myriad-pro-1","myriad-pro-2",sans-serif;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 10px;
}
/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 19px;
}

h1, h2, h3 {
  color: #133174;
}

thead {
  white-space: nowrap;
}

.btn-favorite:focus {
  outline: none;
}

.btn-favorite {
  text-align: center;
  padding: 6px 0px;
  line-height: 1.42;
}

.btn-favorite span {
  font-size: 20px;
  color: red;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}
.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
}
.marketing p + h4 {
  margin-top: 28px;
}

.login-panel{
  width: 90%;
  max-width: 300px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  height: 50px;
}
.v-center{
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
#customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  background: url('/images/g-normal.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: 'Roboto', sans-serif;
}

.has-arrow-right {
  height: 40px;
  position: relative;
  border-top-right-radius: 0;
}
.has-arrow-right:before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  border: 20px solid transparent;
  border-left: 20px solid #133174;
}

.clickable {
  cursor: pointer;
}
ul {
  padding-left: 0px !important;
}
.navbar .navbar-brand {
  padding-top: 7px;
}

/* Horizontal Scroll */
.horizontal-group {
  overflow-x: auto;
  white-space: nowrap;
}
.horizontal-col{
  display: inline-block;
  float: none;
}
.table-fixed {
  table-layout: fixed;
}
.td-wrap {
  word-wrap:break-word
}
.wrap-value {
  white-space: normal !important;
}
.btn-scisa {
  color: #fff;
  background-color: #fc6422;
  border-color: #ccc;
}
.btn-scisa:hover .btn-scisa:focus .btn-scisa.focus{
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

a {
  color: #133174;
}

.pagination > li > a, .pagination > li > span {
  color: #133174;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #133174;
}

.panel-primary > .panel-heading {
  border-color: #133174;
  background-color: #133174;
}

.panel-primary {
  border-color: #133174;
}

.panel-heading:focus{outline:0;border:none;}

.column {
    float: left;
    width: 50%;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Chat containers */
.chat-container {
    border: 2px solid #dedede;
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
}

textarea {
  box-sizing: border-box;
  border-radius: 5px;
}
/* Used for message board box */
.chat
{
    list-style: none;
    margin: 0;
    padding: 0;
}

.chat li
{
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #B3A9A9;
}

.chat li.left .chat-body
{
    margin-left: 60px;
}

.chat li.right .chat-body
{
    margin-right: 60px;
}


.chat li .chat-body p
{
    margin: 0;
    color: #777777;
}

.panel .slidedown .glyphicon, .chat .glyphicon
{
    margin-right: 5px;
}

.panel-body
{
    overflow-y: scroll;
    height: 250px;
}

.tags
{
  border-radius: 4px;
  padding: 5px;
  margin-right: 2px;
  background-color: #133174;
  color: white;
}

.mention-list-container {
  background-color:white;
  border: 1px solid;
  margin-left:15px;
  margin-top:72px;
  max-height:126px;
  position: absolute;
  z-index:1;
}

.mention-list-reply {
  margin-top:132px !important;
}

.mention-list-ul {
  max-height:125px;
  list-style: none;
  overflow:hidden;
  overflow-y:scroll;
  padding-left: 5px !important;
  padding-right:5px;
}

.mention-list-li {
  padding: 10px 0;
  border-bottom: 1px solid;
}

.highlight {
  background-color: #b1d5e5;
}
.yellow-highlight {
  background-color: #fdbb9c;
}

.message-input-lg {
  border: 1px solid black;
  background-color: white;
  height: 120px !important;
  overflow: auto;
}
.attendees-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.attendees-list.show {
  max-height: 800px;
  transition: max-height 0.5s ease-in;
}
.color-labels {
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.label {
  padding: 5px 10px;
  border-radius: 3px;
  color: white;
  flex-grow: 1;
}

.panel-history .panel-heading {
  background-color: #133174;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 5px solid #f46516;
  overflow: auto;
  color: #fff;
  padding: 1.25rem;
  cursor: pointer;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.247);
}

.panel-history .panel-body {
  border: 1px solid #133174;
  display: none;
  padding: 1.25rem;
  background-color: #f8f9fa;
}

.panel-history .panel-body.show {
  display: block;
}

.line {
  border: .1px solid transparent;
  position: relative;
  padding-left: 30px;
}


.line::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: #f46516;
  left: 10px;
  top: 0;
}

.line::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f46516;
  left: 1px;
  top: 0;
}

.panel-heading.selected {
  background-color: #071a41;
  color: #fff;
}

  .mobile-content, .desktop-content {
    display: none;
  }

  @media (max-width: 768px) {
    .mobile-content {
      display: block;
    }

    .desktop-content {
      display: none;
    }
  }

  @media (min-width: 769px) {
    .mobile-content {
      display: none;
    }

    .desktop-content {
      display: block;
    }
  }

  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes scaleOut {
    from {
      opacity: 1;
      transform: translateX(100);
    }
    to {
      opacity: 0;
      transform: translateX(0);
    }
  }

  .animated-in {
    animation: scaleIn 0.4s ease-in-out;
  }

  .animated-out {
    animation: scaleOut 0.4s ease-in-out;
  }

  .transition-height {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
  }

  .expanded {
    max-height: 350px;
  }

  .arrow-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .arrow-item {
    border-top: 4px solid #f46516;
    display: inline-block;
    position: relative;
    padding: 0.5em;
    line-height: 25px;
    color: white;
    background-color: #133174;
    margin-left: 18px;
    margin-bottom: 3px;
    margin-top: 5px;
    padding-left: 25px;
    opacity: 0;
    animation: scaleIn .8s forwards;
  }

  .arrow-item i{
    color: white;
  }

  .arrow-item.canceled {
    border-top: 4px solid #dc3545;
    background-color: #dc3545;
  }
  .arrow-item.in-this-state {
    border-top: 4px solid #c95415;
    background-color: #c95415;
  }

  .arrow-item.completed {
    border-top: 4px solid #336413;
    background-color: #336413;
  }

  .arrow-item:hover {
    cursor: pointer;
  }


  .arrow-item::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: -18px;
    transform: translateY(-55%);
    border-top: 21.5px solid transparent;
    border-left: 18px solid #133174;
    border-bottom: 21.5px solid transparent;
  }

  .arrow-item.canceled::after {
    border-left-color: #dc3545;
  }

  .arrow-item.completed::after {
    border-left-color: #336413;
  }

  .arrow-item.in-this-state::after  {
    border-left-color: #c95415;
  }

  .arrow-item::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-52%);
    border-top: 22px solid transparent;
    border-left: 18px solid #ffffff;
    border-bottom: 25px solid transparent;
  }


  @keyframes inProgressAnimation {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .in-progress {
    animation: inProgressAnimation 5s;
  }

  #div-announcements .panel-body{
    height: 300px;
  }

  md-autocomplete md-autocomplete-wrap {
    border: 1px solid #ccc;
    border-radius: 4px;
    border-bottom: none;
    box-sizing: border-box;
}

.project { background-color: #4153c8; }
.support { background-color: #df6c11; }
.quote { background-color: #7a7db3; }
.internal { background-color: #e3a60d; }
.rh { background-color: #4682b4; }
.vacation { background-color: #5F9EA0; }
.sia { background-color: #c47c42; }
